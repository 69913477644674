<template>
  <div v-show="loaded">
    <div class="vx-row" v-if="item">
      <div class="vx-col w-full lg:w-1/1">
        <!-- ABOUT CARD -->
        <vx-card :title="`Detail ${$i18n.t('OutGoingLetter')}`">

          <!-- ACTION SLOT -->
          <template slot="actions">
            <div class="flex">
              <vs-button color="primary" icon-pack="feather" icon="icon-edit" @click="$router.push({ name: 'outgoing-letter-edit', params: { id: $route.params.id } })" class="mr-4">
                {{ $i18n.t('Edit') }}
              </vs-button>

              <vs-button color="danger" icon-pack="feather" icon="icon-arrow-left" type="border" @click="$router.push({ name: 'outgoing-letter' })">
                {{ $i18n.t('Back') }}
              </vs-button>
            </div>
          </template>

          <div class="vx-row">
            <div class="vx-col w-full lg:w-1/2 mb-2" v-for="(cell, i) in cells" :key="i">
              <div class="mt-5">
                <h6 class="font-bold">{{ cell.header }}</h6>
                <p>
                  <span v-if="cell.format">
                    {{ cell.format(item[cell.field]) }}
                  </span>
                  <span v-else>
                    {{ item[cell.field] }}
                  </span>
                </p>
              </div>
            </div>
          </div>

        </vx-card>

      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "OutGoingLetterDetail",
  data: () => ({
    loaded: false
  }),
  computed: {
    ...mapGetters({
      item: 'outgoingLetter/showItem'
    }),
    cells () {
      return [
        {
          header: this.$i18n.t('LetterNumber'),
          field: 'number',
          format: (field) => {
            return this.formatNum(field);
          }
        },
        {
          header: this.$i18n.t('LetterDate'),
          field: 'tanggal_display'
        },
        {
          header: this.$i18n.t('LetterCreator'),
          field: 'user',
          format: (field) => {
            return field.employee.name;
          }
        },
        {
          header: this.$i18n.t('Subject'),
          field: 'perihal'
        },
        {
          header: this.$i18n.t('PONumber'),
          field: 'nomor_po'
        },
        {
          header: this.$i18n.t('PODate'),
          field: 'tanggal_po_display'
        },
        {
          header: this.$i18n.t('Purpose'),
          field: 'untuk'
        },
        {
          header: this.$i18n.t('Up'),
          field: 'up'
        },
        {
          header: this.$i18n.t('From'),
          field: 'dari'
        },
        {
          header: this.$i18n.t('Description'),
          field: 'keterangan'
        },
        {
          header: this.$i18n.t('FileLocation'),
          field: 'lokasi_file'
        },
        {
          header: this.$i18n.t('NoteReferTo'),
          field: 'note_refer_to'
        }
      ]
    }
  },
  methods: {
    formatNum (field) {
      let number = field.number;

      if (number.toString().length == 1) {
        number = "00" + number;
      } else if (number.toString().length == 2) {
        number = "0" + number;
      }

      return `${number}/${field.code}-AST/${field.month}/${field.year}`;
    },
    loading () {
      this.loaded = false;

      this.$vs.loading({
        type: 'border',
        color: 'primary',
        text: `Wait a minute, Data will be displayed.`
      })

    },

    onLoaded () {
      this.$vs.loading.close();
      this.loaded = true;
    }
  },
  created () {
    this.loading();

    this.id = this.$secure.decrypt(this.$route.params.id);
    this.$store.dispatch('outgoingLetter/detailItem', {id: this.id}).then(() => {
      this.onLoaded();
    }).catch(err => {
      if (!err.response) {
        // network error
        err.response = {
          data: {
            message: 'Server error'
          }
        };
      }

      this.$vs.notify({
        title:'Opps something error',
        text: err.response.data.message,
        color:'danger'})
    });
  }
}
</script>

<style scoped>

</style>
